import { Col, Colors, Content, Label, Row, Spacer } from '@gower/react-ui/components';
import { MdList, MdOutlineCalculate, RiBillLine } from '@gower/react-ui/icons';
import { Redirect } from '@gower/react-ui/utils';
import { RoutesEnum } from 'app/routes';
import { useStore, provider } from './store';

export const HomeView = provider( () => {

    return (
        <HomeTemplate />
    );

}, { viewName: 'HomeView' } );

export const HomeTemplate = () => {

    return <>
        <Content
            columns
            padding={50}
        >
            <Col fullWidth maxWidth={800} center>
                <Row rows>
                    <Col gutter>
                        <Row
                            size={180}
                            columns
                            color="primary"
                            pointer
                            effect="active-transform"
                            hoverColor="blue-4"
                            padding={20}
                            noSelect
                            onClick={() => Redirect.go( RoutesEnum.Payouts )}
                        >
                            <Spacer vertical>
                                <Content justifyCenter>
                                    <Col center>
                                        <MdList size={64} color={Colors['white']} />
                                    </Col>
                                </Content>
                            </Spacer>
                            <Col center textCenter>
                                <Label textWhite bold normal label="Historial de Liquidaciones" />
                            </Col>
                        </Row>
                    </Col>
                    <Col gutter>
                        <Row
                            size={180}
                            columns
                            color="primary"
                            pointer
                            effect="active-transform"
                            hoverColor="blue-4"
                            padding={20}
                            noSelect
                            onClick={() => Redirect.go( RoutesEnum.PayoutInvoices )}
                        >
                            <Spacer vertical>
                                <Content justifyCenter>
                                    <Col center>
                                        <RiBillLine size={64} color={Colors['white']} />
                                    </Col>
                                </Content>
                            </Spacer>
                            <Col center textCenter>
                                <Label textWhite bold normal label="Prestaciones facturadas" />
                            </Col>
                        </Row>
                    </Col>
                    <Col gutter>
                        <Row
                            size={180}
                            columns
                            color="primary"
                            pointer
                            effect="active-transform"
                            hoverColor="blue-4"
                            padding={20}
                            noSelect
                            onClick={() => Redirect.go( RoutesEnum.Calculator )}
                        >
                            <Spacer vertical>
                                <Content justifyCenter>
                                    <Col center>
                                        <MdOutlineCalculate size={64} color={Colors['white']} />
                                    </Col>
                                </Content>
                            </Spacer>
                            <Col center textCenter>
                                <Label textWhite bold normal label="Valores de prestaciones" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Content>
    </>;
};

